import React from "react";
import { Link } from "react-router-dom";

function Preorders() {
    return (
        <div className="detail-box">
<h3 align="center">Upcoming / Preorders</h3>


{/*
<h4 align="center">There are no FiGPiNs on preorder at this time.</h4>
<h4 align="center">No Preorders at this time.</h4>
<ul className="gallery mw-gallery-traditional-left">
<li className="gallerybox"><div className="newrelease-header">Star Wars<br/>Jan 2022</div></li>
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/795">
    <img className="pinlist-image" src="http://s3.us-east-2.amazonaws.com/figpinimages/FigpinImage/12/b5/da/6905/6905.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/796">
    <img className="pinlist-image" src="http://s3.us-east-2.amazonaws.com/figpinimages/FigpinImage/6d/e9/2d/6909/6909.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>
</ul>
*/}

<div className="home-category">Upcoming</div>
<br/>
<div className="videotitle">NYCC 2024 - 10/17/24</div>
<ul className="gallery mw-gallery-traditional-left">   
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/X74">
    <img className="pinlist-image" alt="X74" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/d0/3a/96/14513/14513.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1777">
    <img className="pinlist-image" alt="1607" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/87/9f/5d/14552/14552.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1607">
    <img className="pinlist-image" alt="1607" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/e2/cd/a0/12829/12829.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1654">
    <img className="pinlist-image" alt="1654" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/d3/db/bd/13153/13153.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1740">
    <img className="pinlist-image" alt="1740" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/25/76/22/14541/14541.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li> 
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1710">
    <img className="pinlist-image" alt="1710" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/8c/9c/59/14547/14547.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1368">
    <img className="pinlist-image" alt="1368" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/2b/ae/5e/13035/13035.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1779">
    <img className="pinlist-image" alt="1779" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/7f/4e/d6/14466/14466.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1761">
    <img className="pinlist-image" alt="1761" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/61/6e/dd/14527/14527.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1681">
    <img className="pinlist-image" alt="1681" src="/temp/1681_med.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1774">
    <img className="pinlist-image" alt="1774" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/77/a3/46/14596/14596.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/L106">
    <img className="pinlist-image" alt="L106" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/97/11/c3/14592/14592.ce68e513.png"/><div className="pinname">FiGPiN Exclusive</div></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/kfc">
    <img className="pinlist-image" alt="kfc" src="/temp/kfc_med.png"/><div className="pinname">Shared Exclusive</div></Link></div></li>  
    </ul>
    <br/>

<div className="videotitle">Alliance Fest NYCC 2024 - 10/17/24</div>
<ul className="gallery mw-gallery-traditional-left">   
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1762">
    <img className="pinlist-image" alt="1762" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/32/79/65/14098/14098.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1764">
    <img className="pinlist-image" alt="1764" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/8b/34/5d/14108/14108.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1766">
    <img className="pinlist-image" alt="1766" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/3e/91/f2/14118/14118.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1768">
    <img className="pinlist-image" alt="1768" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/fb/da/64/14128/14128.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1770">
    <img className="pinlist-image" alt="1770" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/60/6f/f1/14138/14138.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1772">
    <img className="pinlist-image" alt="1772" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/32/1b/30/14148/14148.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1763">
    <img className="pinlist-image" alt="1763" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/19/5d/05/14103/14103.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1765">
    <img className="pinlist-image" alt="1765" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/96/67/84/14113/14113.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1767">
    <img className="pinlist-image" alt="1767" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/9d/0d/b2/14123/14123.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1769">
    <img className="pinlist-image" alt="1769" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/76/ce/61/14133/14133.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1771">
    <img className="pinlist-image" alt="1771" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/2e/d7/80/14143/14143.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1773">
    <img className="pinlist-image" alt="1773" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/57/c5/a7/14153/14153.ce68e513.png"/><div className="pinname">Alliance Fest Exclusive</div></Link></div></li>    
</ul>
<br/>

<div className="videotitle">Release Date Unknown</div>
<ul className="gallery mw-gallery-traditional-left">   
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/G14">
    <img className="pinlist-image" alt="G14" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/a1/09/af/13572/13572.ce68e513.png"/></Link></div></li>    
<li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1366">
    <img className="pinlist-image" alt="1366" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/7d/f5/74/11212/11212.ce68e513.png"/></Link></div></li>  
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1619">
    <img className="pinlist-image" alt="1619" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/17/9c/a8/12969/12969.ce68e513.png"/><div className="pinname">Retailer Exclusive</div></Link></div></li>     
    <li className="gallerybox"><div className="pinbox"><Link to="/pinDetail/1560">
    <img className="pinlist-image" alt="1560" src="https://s3.amazonaws.com/figpin-vault-images/FigpinImage/80/a2/be/11931/11931.ce68e513.png"/><div className="pinname">Retailer Exclusive</div></Link></div></li>   
</ul>
<br/>

<div className="home-category">Preorders</div>
<br/>


<div className="home-category">There are no FiGPiNs on preorder at this time.</div>

        </div>
    );
}

export default Preorders;


